import React from "react"
import Layout from "../components/layout"
import ProjectTiles from "../components/project-tiles"

import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <div className="wrapper bg-light">
      <Layout>
        <div className="fluid pt-32">
          <div className="md:text-center">
            <h1 className="text-lg">Page not found</h1>
            <p>
              Sorry, we couldn't find what you were looking for. <Link to="/">Go home</Link>
            </p>
          </div>
        </div>
        <ProjectTiles title="Recent Builds" />
      </Layout>
    </div>
  )
}

export default NotFoundPage
